<template>
  <div class="organ-pipes-map">
    <div class="image-container">
      <img v-if="currentImage" class="image" :src="currentImage" alt="" />
    </div>
    <div v-if="pipeCount" class="navigation">
      <div class="navigation__prev" @click="prevPipe">
        <img class="image" src="@/assets/images/prev.svg" alt="Prev" />
      </div>
      <div class="navigation__pager">{{ currentPipe }} / {{ pipeCount }}</div>
      <div class="navigation__next" @click="nextPipe">
        <img class="image" src="@/assets/images/next.svg" alt="Next" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganPipesMap',
  computed: {
    currentPipe() {
      return this.$store.state.organ.selectedPipe;
    },
    pipeCount() {
      return this.$store.state.organ.pipeVariants
        ? this.$store.state.organ.pipeVariants[
            this.$store.state.organ.selectedDonation
          ].length
        : 0;
    },
    currentImage() {
      const imgObj =
        this.$store.getters['organ/currentPipeVariants'].images[
          this.$store.state.organ.selectedPipe - 1
        ];
      return imgObj ? imgObj.url : null;
    },
  },
  beforeUnmount() {
    this.$store.commit('organ/setSelectedPipe', 1);
  },
  methods: {
    prevPipe() {
      let index =
        this.currentPipe === 1 ? this.pipeCount : this.currentPipe - 1;

      this.$store.commit('organ/setSelectedPipe', index);
    },
    nextPipe() {
      let index =
        this.currentPipe === this.pipeCount ? 1 : this.currentPipe + 1;

      this.$store.commit('organ/setSelectedPipe', index);
    },
  },
};
</script>

<style scoped lang="scss">
.organ-pipes-map {
  width: 100%;
  height: 100%;
  border: 1px solid #444444;
  border-radius: 25px;
  background-color: #fff;
  padding: 50px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  .image-container {
    padding: 0 50px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .image {
    width: 100%;
    height: auto;
  }
  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    &__pager {
      font-size: 20px;
      font-weight: 50;
      color: #b98d57;
    }

    &__prev,
    &__next {
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}
</style>
